<script>
import stepsMixin from '@/mixins/stepsMixin'
import { mapGetters, mapMutations } from 'vuex'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('../../components/SvgIcon')
	},
	data() {
		return {

		}
	},
	computed: {
		...mapGetters({
			showHelp: 'arceStatus/showHelp'
		})
	},
	methods: {
		checkHelpers() {
			let help = this.$route.params.help == 'true' || this.$route.params.help == 'help'
			if (help) {
				this._setHelp(help)
			}
		},
		...mapMutations({
			_setHelp: 'arceStatus/setHelp'
		})
	},
	mounted() {
		this.checkHelpers()
		this.hiddenSteps()
		this.showSteps('myTour')
	}
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1">
		<div class="col-12 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header bg-light">
							<div class="d-flex flex-wrap py-1">
								<div class="col-10">
									<div class="btn btn-info btn-rounded h-icon pr-3">
										<svg-icon name="custom_apps" />
									</div>
									<div class="ml-5">
										<p class="mb-0 text-muted">Local Client</p>
										<h6 class="mt-0 font-weight-bold">TUM TRANSPORTISTAS UNIDOS MECICANOS DIVISION
											NORTE, SA DE CV</h6>
									</div>
								</div>
								<div class="col-2 text-right">
									<div class="btn btn-light btn-link text-primary mt-2 p-1 pl-4 pr-4 border-secondary">
										Limit Advisor
									</div>
								</div>
							</div>
						</div>
						<div class="s-panel__body">
							<div class="d-flex flex-wrap mt-2 py-1">
								<div class="col-4">
									<p class="mb-0 text-muted">Name</p>
									<p>TUM TRANSPORTISTAS UNIDOS MECICANOS DIVISION NORTE, SA DE CV</p>
								</div>
								<div class="col-1">
									<p class="mb-0 text-muted">Active</p>
									<input type="checkbox">
								</div>
								<div class="col-4">
									<p class="mb-0 text-muted">Global Client</p>
									<a href="#">TUM TRANSPORTISTAS UNIDOS MECICANOS DIVISION NORTE, SA DE CV</a>
								</div>
								<div class="col-3">
									<p class="mb-0 text-muted">Country ode Residence</p>
									<p>México</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 col-lg-8 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__body p-0">
							<div class="d-flex flex-wrap mt-2 py-1 pb-2">
								<div class="col-12">
									<ul class="nav nav-tabs" id="myTab" role="tablist">
										<li class="nav-item">
											<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home"
												role="tab" aria-controls="home" aria-selected="true">Client Info</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile"
												role="tab" aria-controls="profile" aria-selected="false">Studies</a>
										</li>
									</ul>
									<div class="tab-content">
										<div class="tab-pane show active" id="home" role="tabpanel"
											aria-labelledby="home-tab">
											<div class="col-12 mt-3 p-0">

												<div class="accordion" id="accordion">
													<div class="card border-white">
														<div class="card-header p-0" id="headingBasic">
															<h5 class="mb-0">
																<a class="btn text-left btn-link text-muted ml-2"
																	data-toggle="collapse" data-target="#collapseBasic"
																	aria-expanded="false" aria-controls="collapseBasic">
																	Basic Info
																</a>
															</h5>
														</div>

														<div id="collapseBasic" class="collapse show"
															aria-labelledby="headingBasic" data-parent="#accordion">
															<div class="card-body p-0 pt-2">
																<div class="d-flex flex-wrap mt-2 py-1">
																	<div class="col-6">
																		<p class="mb-0 text-muted">Alpha code </p>
																		<input type="text" class="form-control under mt-0"
																			value="MX0074022007739">
																	</div>
																	<div class="col-6">
																		<p class="mb-0 text-muted">Tax ID </p>
																		<input type="text" class="form-control under mt-0"
																			value="TTU97007DM2">
																	</div>
																	<div class="col-6 mt-2">
																		<p class="mb-0 text-muted">Local Client Name </p>
																		<input type="text" class="form-control under mt-0"
																			value="TUM TRANSPORTISTAS UNIDOS MECICANOS DIVISION NORTE, SA DE CV">
																	</div>
																	<div class="col-6 mt-2">
																		<p class="mb-0 text-muted">Booking country code </p>
																		<input type="text" class="form-control under mt-0"
																			value="MX (Bancomer)">
																	</div>
																	<div class="col-6 mt-2">
																		<p class="mb-0 text-muted">Local Client Name </p>
																		<input type="text" class="form-control under mt-0"
																			value="TUM TRANSPORTISTAS UNIDOS MECICANOS DIVISION NORTE, SA DE CV">
																	</div>
																	<div class="col-6 mt-2">
																	</div>
																	<div class="col-6 mt-2">
																		<p class="mb-0 text-muted">CrossBorder</p>
																		<div class="form-group-inputs">
																			<input type="checkbox" class="check">
																			<input type="text"
																				class="form-control under mt-0 pl-4">
																		</div>

																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">

										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 col-lg-4 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__body">
							<div class="col-12">
								<ul class="nav nav-tabs" id="myTab" role="tablist">
									<li class="nav-item">
										<a class="nav-link active" id="related-tab" data-toggle="tab" href="#related"
											role="tab" aria-controls="related" aria-selected="true">Related</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab"
											aria-controls="activity" aria-selected="false">Activity</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" id="chatter-tab" data-toggle="tab" href="#chatter" role="tab"
											aria-controls="chatter" aria-selected="false">Chatter</a>
									</li>
								</ul>
								<div class="tab-content">
									<div class="tab-pane show active" id="related" role="tabpanel"
										aria-labelledby="related-tab">
										<div class="col-12 mt-3 p-0">
											<div id="accordion2"  class="accordion2">
												<div class="card border-white border-light mt-2">
													<div class="card-header p-0" id="headingNotes">
														<h5 class="mb-0">
															<a class="btn text-left btn-link text-muted ml-2"
																data-toggle="collapse" data-target="#collapseNotes"
																aria-expanded="true" aria-controls="collapseNotes">
																<span class="badge badge-warning text-light mr-2 ">
																	<h6 class="mb-0	">
																		<svg-icon name="note" />
																	</h6>
																</span>
																Notes (0)
															</a>
														</h5>
													</div>

													<div id="collapseNotes" class="collapse show" aria-labelledby="headingNotes"
														data-parent="#accordion2">
														<div class="card-body p-0 pt-2  text-center">
															Notes
														</div>
													</div>
												</div>

												<div class="card border-white border-light mt-2">
													<div class="card-header p-0" id="headingFiles">
														<h5 class="mb-0">
															<a class="btn text-left btn-link text-muted ml-2"
																data-toggle="collapse" data-target="#collapseFiles"
																aria-expanded="true" aria-controls="collapseFiles">
																<span class="badge badge-coffe text-light mr-2 ">
																	<h6 class="mb-0	">
																		<svg-icon name="file" />
																	</h6>
																</span>
																Files (0)
															</a>
														</h5>
													</div>

													<div id="collapseFiles" class="collapse show" aria-labelledby="headingFiles"
														data-parent="#accordion2">
														<div class="card-body p-0 pt-2">
															<div class="col-12 mt-3 text-center p-0">
																<input type="file" name="file" id="file" class="inputfile" />
																<label for="file"><svg-icon name="upload" /> Upload Files</label>															
																<p>
																	Or drop files
																</p>

															</div>
														</div>
													</div>
												</div>

												<div class="card border-white border-light  mt-2">
													<div class="card-header p-0" id="headingAdvisor">
														<h5 class="mb-0">
															<a class="btn text-left btn-link text-muted ml-2"
																data-toggle="collapse" data-target="#collapseAdvisor"
																aria-expanded="true" aria-controls="collapseAdvisor">
																<span class="badge badge-secondary text-light mr-2 ">
																	<h6 class="mb-0	">
																		<svg-icon name="custom_apps" />
																	</h6>
																</span>
																Limit_Advisor (0)
															</a>
														</h5>
													</div>

													<div id="collapseAdvisor" class="collapse show" aria-labelledby="headingAdvisor"
														data-parent="#accordion2">
														<div class="card-body p-0 pt-2 text-center">
															Advisor
														</div>
													</div>
												</div>

											</div>
										</div>


									</div>
									<div class="tab-pane" id="activity" role="tabpanel" aria-labelledby="activity-tab">
										activity
									</div>
									<div class="tab-pane" id="chatter" role="tabpanel" aria-labelledby="chatter-tab">
										chatter
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
